import { defineStore } from "pinia";

// @ts-ignore
// @ts-ignore
export const ActorStore = defineStore({
  id: "actorStore",
  state: () => ({
    actors: {
      nobody: "Noone",
    },
    featuredActor: {},
  }),
  actions: {
    setActors(actors: object) {
      // @ts-ignore
      this.actors = actors;
    },
    setActor(id: string, name: string) {
      // @ts-ignore
      this.actors[id] = name;
    },
    getActorById(id: string): string {
      // @ts-ignore
      return this.actors[id];
    },
    setFeaturedActor(featuredActor: object) {
      this.featuredActor = featuredActor;
    },
  },
  getters: {
    getActors(): object {
      // @ts-ignore
      return this.actors;
    },
    getFeaturedActor(): object {
      // @ts-ignore
      return this.featuredActor;
    },
  },

  persist: {
    enabled: true,
    strategies: [
      {
        key: "flxActors",
        storage: localStorage,
      },
    ],
  },
});
